import { productSelectors } from '@ynap/product-state';

const adaptProductsData = (products, pdpUrlTemplate, locale, imageSphereConfig) => {
    const { getMappedProduct, getProductImage } = productSelectors;
    const badgeBlocklist = [];
    const { availableImageSizes, imageQualityPercentage } = imageSphereConfig;

    return products.map(product => {
        const { price, href, badge, saleExclusive } = getMappedProduct({
            id: product.partNumber,
            product,
            badgeBlocklist,
            locale: locale.id,
            availableImageSizes,
            pdpUrlTemplate,
            imageQuality: imageQualityPercentage
        });
        const imgSrc = getProductImage(product, [220, 360], 80);
        return { ...product, price, imgSrc, href, badge, saleExclusive };
    });
};

export default adaptProductsData;
