export const createMatchedRule = (source, options) => {
    const reg = new RegExp(`(${source.join('|')})`, 'i');

    return doc => {
        if (reg.test(doc[options.field || 'label'])) {
            if (options.synonyms) {
                doc.synonyms = (doc.synonyms || []).concat(options.synonyms);
            } else if (options.boost) {
                doc.boost = (doc.boost || 1) * options.boost;
            }
        }
        return doc;
    };
};

export const compileRules = rules => {
    return rules.map(rule => {
        if (rule.type === 'cluster') {
            return createMatchedRule(rule.target, { synonyms: rule.target });
        } else if (rule.type === 'synonym') {
            return createMatchedRule(rule.target, { synonyms: rule.query });
        } else if (rule.type === 'boost') {
            return createMatchedRule(rule.target, { boost: rule.boost, field: rule.field });
        } else {
            return doc => doc;
        }
    });
};

export const applyRules = (doc, rules) => {
    rules.forEach(rule => {
        doc = rule(doc);
    });
    return doc;
};

const scaleCount = x => 1 + x / (100 + x);
const depthBoost = x => (x ? 5 - x : 2);

export const generateTags = (depth, label, parent) => {
    return depth > 1 && parent && !/\s/.test(label + parent) ? [[parent, label].join(' '), [label, parent].join(' ')] : [label];
};

export const applyDefaultRules = doc => {
    const { parent, label, labelEn, parentEn, count, depth } = doc;
    doc.boost = (doc.boost || 1) * depthBoost(depth || 2) * scaleCount(count || 20);
    doc.tags = generateTags(depth, label, parent);
    if (/\./.test(label)) {
        doc.tags.push(label.replace(/\./g, ''));
    }
    if (labelEn) {
        doc.tags = doc.tags.concat(generateTags(depth, labelEn, parentEn));
    }
    doc.tags = doc.tags.concat(label.split(/\s/));
    return doc;
};
