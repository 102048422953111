import match from './matchWord';
import parse from 'autosuggest-highlight/parse';
import lunr from 'lunr';
import { applyRules, compileRules, applyDefaultRules } from './ApplyRules';
require('./lunr.unicodeNormalizer')(lunr);
require('lunr-languages/tinyseg')(lunr);
require('lunr-languages/lunr.stemmer.support')(lunr);
require('lunr-languages/lunr.ar')(lunr);
require('lunr-languages/lunr.ko')(lunr);
require('lunr-languages/lunr.ja')(lunr);
require('lunr-languages/lunr.de')(lunr);
require('lunr-languages/lunr.multi.js')(lunr);

export class Searcher {
    constructor(documents, rules, language) {
        this.documents = documents;
        this.documentIndex = {};
        this.rules = rules;
        this.language = language;
    }
    index() {
        const indexDocuments = this.indexDocuments.bind(this);
        lunr.tokenizer.separator = /dontseperate/;
        const language = this.language;
        this.lunrIndex = lunr(function() {
            this.use(lunr.unicodeNormalizer);
            if (language !== 'en') {
                this.use(lunr.multiLanguage(language, 'en'));
            }
            this.ref('url');
            this.pipeline.remove(lunr.stopWordFilter);
            this.pipeline.remove(lunr.stemmer);
            this.searchPipeline.remove(lunr.stemmer);
            this.searchPipeline.remove(lunr.stopWordFilter);
            this.field('label', { boost: 50 });
            this.field('parent', { boost: 2 });
            this.field('synonyms', { boost: 1 });
            this.field('tags', { boost: 1 });
            this.field('category', { boost: 1 });
            indexDocuments(this.add.bind(this));
        });
    }
    indexDocuments(addFn) {
        const compiledRules = compileRules(this.rules);
        this.documentIndex = {};
        this.documents.forEach(doc => {
            doc = { ...doc };
            this.documentIndex[doc.url] = doc;
            doc = applyDefaultRules(doc);
            doc = applyRules(doc, compiledRules);
            addFn(doc, { boost: doc.boost || 1 });
        });
    }
    search(searchTerm) {
        const terms = lunr.tokenizer(searchTerm);
        const isMulti = searchTerm.split(/\s/).length > 1;
        let searchResults = this.lunrIndex.query(q => {
            let options = {};

            q.term(searchTerm, {
                boost: 10000,
                usePipeline: false,
                ...options
            });
            q.term(searchTerm, {
                boost: 8000,
                usePipeline: true,
                wildcard: lunr.Query.wildcard.TRAILING,
                fields: ['label']
            });
            q.term(searchTerm, {
                boost: 100,
                usePipeline: false,
                wildcard: lunr.Query.wildcard.TRAILING,
                ...options
            });

            q.term(searchTerm.replace(/\s+/g, ''), {
                boost: 100,
                usePipeline: false,
                wildcard: lunr.Query.wildcard.TRAILING,
                ...options
            });

            q.term(terms, {
                boost: 5,
                usePipeline: false,
                presence: isMulti ? lunr.Query.presence.REQUIRED : lunr.Query.presence.OPTIONAL,
                wildcard: lunr.Query.wildcard.TRAILING,
                ...options
            });
            if (!isMulti && searchTerm.length > 2) {
                q.term(terms, {
                    boost: 1,
                    usePipeline: false,
                    wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING
                });
            }
        });
        if (searchResults.length === 0) {
            searchResults = this.lunrIndex.query(q => {
                q.term(terms, {
                    boost: 1,
                    usePipeline: true,
                    wildcard: lunr.Query.wildcard.TRAILING,
                    editDistance: 1
                });
            });
        }
        const shouldHighlight = this.language !== 'ar';
        searchResults = searchResults.slice(0, 8).map(result => {
            result = {
                ...result,
                ...this.documentIndex[result.ref]
            };
            if (shouldHighlight) {
                result.highlight = parse(result.label, match(result.label, searchTerm));
                if (result.parent) {
                    result.parentHighlight = parse(result.parent, match(result.parent, searchTerm));
                }
            } else {
                result.highlight = [{ text: result.label }];
                if (result.parent) {
                    result.parentHighlight = [{ text: result.parent }];
                }
            }

            try {
                result.isSynonym = Object.keys(Object.values(result.matchData.metadata)[0]).toString() === 'synonyms';
            } catch (e) {}
            return result;
        });
        const totalCount = searchResults.length;
        searchResults = searchResults.reduce((acc, item) => {
            acc[item.category] = (acc[item.category] || []).concat(item);
            return acc;
        }, {});
        searchResults = Object.entries(searchResults)
            .map(([title, children]) => ({ title, children }))
            .sort((a, b) => {
                const rankA = a.children[0].score;
                const rankB = b.children[0].score;
                return rankA < rankB;
            });
        let currentCount = 1;
        searchResults.forEach(group => {
            group.children.forEach(item => {
                item.index = currentCount++;
            });
        });
        return { groups: searchResults, totalCount };
    }
}
