import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BEMHelper from '@ynap/bem-helper';
import { ProductSummaryWithLink } from '@ynap/product-summary/src/ProductSummaryWithLink/ProductSummaryWithLink';
import track from 'react-tracking';
import { trackSearchSelect } from '../analytics';
import { Button } from '@ynap/button';
import ImmutablesProvider from '@ynap/immutables-provider';

import adaptProductData from '../../../Utils/ProductDataAdapter';
import GenderSwitch from '../../GenderSwitch/GenderSwitch';

export const bem = new BEMHelper('SearchResultsNew');

const highlight = highlightElements => {
    return highlightElements.map((item, index) => {
        if (item.highlight)
            return (
                <span key={index} className={bem('highlight')}>
                    {item.text}
                </span>
            );
        return <span key={index}>{item.text}</span>;
    });
};

@track({
    effect: 'form',
    category: {
        primaryCategory: 'internal search'
    }
})
class SearchResults extends PureComponent {
    renderProducts = (startingIndex, total) => {
        const {
            result: {
                products: { recordSetTotal, products }
            },
            messages,
            locale,
            urls,
            config,
            timeStarted,
            value,
            productSearchTerm,
            browsingGenderPreference
        } = this.props;

        const isEUCountry = config?.head?.minimumPriceCountries.includes(locale.country);
        const hasLimitedResults = products.length < config?.search?.maxProducts;
        const pdpUrlTemplate = urls?.pdp?.construct;
        const newProducts = adaptProductData(products, pdpUrlTemplate, locale, config.imageSphere);
        const title = messages.search.products();
        const menswearEnabled = config.multiShop?.enabled && !config.multiShop?.disableCountries?.includes(locale.country.toUpperCase());
        const genderSearchUrl = menswearEnabled && browsingGenderPreference === 'mens' ? 'mens/' : '';
        return (
            <div className={bem('list', 'products')}>
                <div className={bem('list', 'title')}>{title}</div>
                <ul className={bem('container', ['products', hasLimitedResults ? 'alignLeft' : ''])}>
                    {' '}
                    {newProducts.map((product, index) => (
                        <ImmutablesProvider key={product.productId} value={{ config }}>
                            <li className={bem('item', hasLimitedResults ? 'increaseMargin' : '')}>
                                <ProductSummaryWithLink
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.tracking.trackEvent(
                                            trackSearchSelect({
                                                enteredTerm: value,
                                                selectedTerm: `${product.designerNameEN} - ${product.name}`,
                                                suggestionCategory: title,
                                                totalSuggestionCount: total,
                                                selectedSuggestionIndex: startingIndex + index + 1,
                                                timeToSearch: Date.now() - timeStarted,
                                                selectedSuggestionCategoryIndex: index + 1
                                            })
                                        );
                                        this.props.navigateTo(`/${locale.id}/shop/product/${product.partNumber}`);
                                    }}
                                    nativelink={'true'}
                                    withIntersectionObserver={false}
                                    product={product}
                                    messages={messages}
                                    imageSrcsetSize="(min-width: 768px) 16vw (min-width: 1024px) 25vw, 180px"
                                    className={bem('list', 'product')}
                                    locale={locale.id}
                                    searchResults={true}
                                    country={locale.country}
                                    isEUcountry={isEUCountry}
                                    listingPage={true}
                                />
                            </li>
                        </ImmutablesProvider>
                    ))}
                </ul>
                <Button
                    onClick={() => {
                        const encodedSearchTerm = encodeURIComponent(productSearchTerm.trim().toLowerCase());
                        window.location.assign(`/${locale.id}/shop/${genderSearchUrl}search/${encodedSearchTerm}`);
                    }}
                    className={bem('emptyLink', products.length === 1 ? 'hidden' : '')}
                >
                    {`${messages.search.viewAll({ count: recordSetTotal })}`}
                </Button>
            </div>
        );
    };

    renderResults(results, totalSuggestionCount) {
        // return <pre>hhh{JSON.stringify(results, null, 2)}</pre>;
        const { locale, focusedIndex, value, messages } = this.props;
        const groupTitle = key => {
            return messages.search[key.toLowerCase()] ? messages.search[key.toLowerCase()]() : key;
        };
        return (
            <div>
                {results.map(group => {
                    return (
                        <React.Fragment key={group.title}>
                            <div className={bem('list', 'title')}>{groupTitle(group.title)}</div>
                            <ul className={bem('list', 'categories', locale?.dir === 'rtl' ? 'rtl' : null)}>
                                {group.children.map((suggestion, index) => {
                                    return (
                                        <li
                                            key={suggestion.url}
                                            {...(suggestion.index - 1 === focusedIndex && { className: bem('list', null, 'hasKeyBoardOverrideFocus') })}
                                            onMouseEnter={() => this.props.setIndex(suggestion.index - 1)}
                                            onMouseLeave={() => this.props.setIndex(null)}
                                        >
                                            <a
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.tracking.trackEvent(
                                                        trackSearchSelect({
                                                            enteredTerm: value,
                                                            selectedTerm: suggestion.label,
                                                            isSuggestion: true,
                                                            suggestionCategory: suggestion.category,
                                                            totalSuggestionCount: totalSuggestionCount,
                                                            selectedSuggestionIndex: suggestion.index,
                                                            searchBoost: suggestion.boost,
                                                            isSynonym: suggestion.isSynonym,
                                                            timeToSearch: Date.now() - this.props.timeStarted,
                                                            selectedTermCategory: suggestion.parent ? suggestion.parent : null,
                                                            selectedSuggestionCategoryIndex: index + 1
                                                        })
                                                    );
                                                    this.props.navigateTo(`/${locale.id}${suggestion.url}`);
                                                }}
                                                href={`/${locale.id}${suggestion.url}`}
                                                className={bem('list', null, 'suggestion')}
                                            >
                                                {suggestion.depth >= 1 && suggestion.parent ? (
                                                    <span className={bem('prefix')}>{highlight(suggestion.parentHighlight)} / </span>
                                                ) : null}
                                                {highlight(suggestion.highlight)}
                                                {suggestion.count ? (
                                                    <span className={bem('count', locale?.dir === 'rtl' ? 'rtl' : null, 'notranslate')}>
                                                        {suggestion.count}
                                                    </span>
                                                ) : null}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }

    noResult = searchTerm => {
        const { messages } = this.props;
        return (
            <div className={bem('noresult')}>
                <div>
                    <div className={bem('icon', 'search')} onClick={this.handleOnSubmit} />
                    <div className={bem('content', 'title')}>{messages.search.noResult({ searchTerm: `"${searchTerm}"` })}</div>
                    <div className={bem('content', 'tryagain')}>{messages.search.tryAgain()}</div>
                </div>
            </div>
        );
    };

    loading = () => {
        const { messages } = this.props;
        return (
            <div className={bem('loading')}>
                <div className={bem('loading-content')}>
                    <div className={bem('dots', 'one')} />
                    <div className={bem('dots', 'two')} />
                    <div className={bem('dots', 'three')} />
                    <div className={bem('loading-text')}>{messages.search.loading()}</div>
                </div>
            </div>
        );
    };

    render() {
        const {
            results,
            result,
            value,
            totalCount,
            isSearching,
            messages,
            browsingGenderPreference,
            setBrowsingGenderPreference,
            findingResults,
            config,
            locale
        } = this.props;

        const { multiShop } = config;
        const displayProductSuggestions = result?.products?.products?.length > 0;
        const noResultsFound = !isSearching && value !== '' && !displayProductSuggestions && results.length === 0;
        const totalSuggestionCount = displayProductSuggestions ? totalCount + 4 : totalCount;
        const startingIndex = totalCount;

        if ((findingResults || isSearching) && results.length === 0 && value.trim().length > 0) {
            return <div className={bem()}>{this.loading()}</div>;
        }

        return (
            <div className={bem()}>
                {multiShop.enabled && !multiShop.disableCountries.includes(locale.country) && (
                    <GenderSwitch
                        messages={messages}
                        activeGender={browsingGenderPreference}
                        handleToggleFn={setBrowsingGenderPreference}
                        componentType="internal search"
                    />
                )}
                {this.renderResults(results, totalSuggestionCount)}
                {displayProductSuggestions ? this.renderProducts(startingIndex, totalSuggestionCount) : null}
                {!findingResults && noResultsFound && this.noResult(value)}
            </div>
        );
    }
}

SearchResults.propTypes = {
    locale: PropTypes.shape({
        id: PropTypes.string.isRequired,
        country: PropTypes.string
    }).isRequired,
    result: PropTypes.object,
    focusedIndex: PropTypes.number
};

export default SearchResults;
