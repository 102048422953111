import React, { PureComponent, createRef } from 'react';
import BEMHelper from '@ynap/bem-helper';
import { raygun } from '@ynap/clientside-monitoring';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { debounce } from 'throttle-debounce';
import SearchResults from '../SearchResults/SearchResults';
import { Searcher } from '../SearchSuggest/searcher';
const removeDiacritics = require('diacritics').remove;

import { trackSearchStart, trackSearchSelect, trackNoResults } from '../analytics';
export const bem = new BEMHelper('SearchOverlayNew');

const keyBoardInputOverrideCodes = ['Tab', 'ArrowUp', 'ArrowDown', 'Enter'];

@track({
    effect: 'form',
    category: {
        primaryCategory: 'internal search'
    }
})
class SearchOverlay extends PureComponent {
    state = {
        value: '',
        results: [],
        currentProductSearch: '',
        timeStarted: undefined,
        totalCount: undefined,
        currentFocusedSuggestion: null,
        browsingGenderPreference: 'womens',
        findingResults: false,
        genderToggleUpdate: false
    };

    searchInputRef = createRef();

    checkNoResults = debounce(600, value => {
        this.props.tracking.trackEvent(trackNoResults(value));
    });

    searchProducts = debounce(300, searchTerm => {
        const { browsingGenderPreference } = this.state;
        this.props.fetchSuggestions(browsingGenderPreference, searchTerm, { suggest: false, products: true }).then(res => {
            const { currentProductSearch } = this.state;
            if (currentProductSearch === searchTerm) {
                this.setState({
                    result: { products: res[0].json },
                    resultsSearchTerm: searchTerm,
                    isSearching: false
                });
            }
        });
    });

    loadSuggestions(genderPreference) {
        const { locale, config } = this.props;
        const suggestionsLocale = this.getSearchIndexVersion();
        const suggestionsVersion = genderPreference === 'mens' ? `-mens${suggestionsLocale}` : suggestionsLocale;
        const env = config.search.staging ? 'stage-' : '';
        var rulesEndpoint = `https://www.theoutnet.com/assets/${env}suggestion-rules.json`;
        var suggestionsEndpoint = `https://www.theoutnet.com/assets/${env}ton-suggestion-data${suggestionsVersion}.json`;
        const rulesPromise = fetch(rulesEndpoint).then(response => response.json());
        const suggestionsPromise = fetch(suggestionsEndpoint).then(response => response.json());
        this.searcherPromise = Promise.all([suggestionsPromise, rulesPromise])
            .then(([suggestions, rules]) => {
                const searcher = (window.searcher = new Searcher(suggestions, rules, locale.language));
                searcher.index();
                this.setState({ findingResults: true });
                return searcher;
            })
            .catch(error => {
                raygun.helpers.agent('send', { error, tags: ['sitefurniture', 'Fetch search data failed'] });
            });
    }

    getSearchIndexVersion() {
        const { locale } = this.props;
        if (['US', 'CA'].includes(locale.country)) {
            return '-am';
        } else if (locale.language === 'ja') {
            return '-ja';
        } else if (locale.language === 'ar') {
            return '-ar';
        } else if (locale.language === 'de') {
            return '-de';
        } else if (locale.language === 'ko') {
            return '-ko';
        }
        return '';
    }

    componentDidMount() {
        this.addKeyHandler();
        this.searchInputRef.current.focus();
        const genderPreferenceCookieMatcher = new RegExp('ton-shop=');
        const getGenderPreferenceCookie = document.cookie.split(';').filter(cookieValue => !!cookieValue.match(genderPreferenceCookieMatcher));
        const userGenderPreference =
            getGenderPreferenceCookie.length > 0 ? getGenderPreferenceCookie[0].replace(genderPreferenceCookieMatcher, '').trim() : 'womens';
        this.setState({ browsingGenderPreference: userGenderPreference });
        this.loadSuggestions(userGenderPreference);
    }

    componentWillUnmount() {
        this.removeKeyHandler();
        this.props.reset();
    }

    componentDidUpdate() {
        if (this.state.genderToggleUpdate) {
            this.loadSuggestions(this.state.browsingGenderPreference);
            this.state.value && this.searchProducts(this.state.value);
            this.state.value && this.inputSearch(this.state.value);
            this.setState({ genderToggleUpdate: false, isSearching: true });
        }
    }

    navigateTo = url => {
        if (window.__blueLobsterHistory) {
            window.__blueLobsterHistory.push(url, {});
            this.props.handleClick();
        } else {
            window.location.assign(url);
        }
    };

    handleChange = async e => {
        let { value } = e.target;
        this.setState({ value, currentFocusedSuggestion: null });
        this.inputSearch(value);
    };

    inputSearch = async value => {
        value = value.trim();
        if (!this.state.timeStarted && value.length > 0) {
            this.setState({ timeStarted: Date.now() });
            this.props.tracking.trackEvent(trackSearchStart());
        }

        if (value.length === 0) {
            this.setState({ results: [], findingResults: false, currentFocusedSuggestion: null });
            this.props.reset();
        } else {
            const searcher = await this.searcherPromise;
            const { groups, totalCount } = searcher.search(value);
            let possibleProductSearch = groups[0]?.children[0]?.label;
            this.setState({ results: groups, findingResults: false, totalCount: totalCount });
            if (totalCount === 0) {
                this.checkNoResults(value);
                possibleProductSearch = value;
            }

            possibleProductSearch = removeDiacritics(possibleProductSearch);

            if (possibleProductSearch != this.state.currentProductSearch) {
                this.props.reset();
                this.setState({
                    currentProductSearch: possibleProductSearch,
                    isSearching: true
                });
            }
            this.searchProducts(possibleProductSearch);
        }
    };

    handleOnSubmit = e => {
        e.preventDefault();
        const { value, timeStarted, browsingGenderPreference } = this.state;
        const menswearNavigation = browsingGenderPreference === 'mens' ? 'mens/' : '';
        if (value) {
            this.props.tracking.trackEvent(
                trackSearchSelect({
                    isSuggestion: false,
                    enteredTerm: value,
                    selectedTerm: value,
                    suggestionCategory: 'SearchBar',
                    timeToSearch: Date.now() - timeStarted,
                    selectedSuggestionIndex: null
                })
            );
            this.navigateTo(`/${this.props.locale.id}/shop/${menswearNavigation}search/${encodeURIComponent(this.state.value.trim())}`);
            return;
        }
    };

    handleOnClick = e => {
        e.preventDefault();
        this.setState({
            value: '',
            results: [],
            findingResults: false,
            currentFocusedSuggestion: null
        });
        this.props.reset();
        this.searchInputRef.current.focus();
        return;
    };

    searchInputDangerousKeyboardOverrideHandler = e => {
        if (!keyBoardInputOverrideCodes.includes(e.code)) {
            return;
        }

        e.preventDefault();
        const { code, shiftKey } = e;

        const { locale } = this.props;
        const { currentFocusedSuggestion, results, totalCount } = this.state;

        if (code === 'Enter') {
            if (currentFocusedSuggestion == null) {
                this.handleOnSubmit(e);
            } else {
                const suggestionsURLs = results.reduce((acc, curr) => acc.concat(curr.children.map(suggestion => `/${locale.id}${suggestion.url}`)), []);
                const targetRedirectLink = typeof currentFocusedSuggestion !== 'undefined' ? suggestionsURLs[currentFocusedSuggestion] : this.state.value;
                return this.navigateTo(targetRedirectLink);
            }
        }

        const minIndex = 0;
        const shouldDecrementIndex = code === 'ArrowUp' || (shiftKey && code === 'Tab');
        const indexChange = shouldDecrementIndex ? -1 : 1;

        let newIndex;

        if (isNaN(parseInt(currentFocusedSuggestion))) {
            return this.setState({
                currentFocusedSuggestion: minIndex
            });
        }

        // ensure indices are cycled through appropriately
        newIndex = (currentFocusedSuggestion + totalCount + indexChange) % totalCount;

        return this.setState({
            currentFocusedSuggestion: newIndex
        });
    };

    keyUpHandler = e => {
        this.searchInputDangerousKeyboardOverrideHandler(e);
    };

    keyDownHandler = e => {
        if (keyBoardInputOverrideCodes.includes(e.code)) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    addKeyHandler = () => {
        document.addEventListener('keyup', this.keyUpHandler);
        document.addEventListener('keydown', this.keyDownHandler);
        //might have needed this for some browser that capture keypress even when keydown and up have been prevented
        // document.addEventListener('keypress', this.keyDownHandler);
    };

    removeKeyHandler = () => {
        document.removeEventListener('keyup', this.keyUpHandler);
        document.removeEventListener('keydown', this.keyDownHandler);
        //might have needed this for some browser that capture keypress even when keydown and up have been prevented
        // document.removeEventListener('keypress', this.keyDownHandler);
    };

    setIndex = index => {
        this.setState({
            currentFocusedSuggestion: index
        });
    };

    setBrowsingGenderPreference = genderSelection => {
        if (genderSelection !== this.state.browsingGenderPreference) {
            this.setState({ browsingGenderPreference: genderSelection, genderToggleUpdate: true, results: [], result: [], findingResults: true });
        }
    };

    render() {
        let { messages, handleClick } = this.props;
        let { results, result, resultsSearchTerm, value, currentProductSearch, currentFocusedSuggestion, totalCount, timeStarted } = this.state;
        result = value && currentProductSearch && resultsSearchTerm === currentProductSearch ? result : {};
        //     this.state.currentProductSearch.toLowerCase() === removeDiacritics(result?.products?.searchTermView?.searchTerm || '').toLowerCase()
        //         ? result
        //         : {};

        return (
            <div className={bem()}>
                <div className={bem('content')}>
                    <div className={bem('control')}>
                        <div className={bem('icon', this.state.value ? 'search' : 'searchDisabled')} onClick={this.handleOnSubmit} />
                        <form className={bem('form')} onSubmit={this.handleOnSubmit}>
                            <input
                                ref={this.searchInputRef}
                                type="text"
                                value={this.state.value}
                                onChange={this.handleChange}
                                placeholder={messages.search.text()}
                                className={bem('input')}
                                autoFocus
                            />
                            {/* TODO: <div className={bem('submit-button', { show: !!this.state.value })} onClick={this.handleOnSubmit}>{messages.search.cta()}</div> */}
                        </form>
                        <div className={bem('clear', this.state.value === '' ? 'clearDisabled' : 'clearEnabled')} onClick={this.handleOnClick}>
                            {messages.search.clear()}
                        </div>
                    </div>
                    <div className={bem('icon', 'close')} onClick={handleClick} />
                    <SearchResults
                        {...this.props}
                        browsingGenderPreference={this.state.browsingGenderPreference}
                        setBrowsingGenderPreference={this.setBrowsingGenderPreference}
                        findingResults={this.state.findingResults}
                        setIndex={this.setIndex}
                        result={result}
                        results={results}
                        value={value}
                        productSearchTerm={currentProductSearch}
                        focusedIndex={currentFocusedSuggestion}
                        totalCount={totalCount}
                        timeStarted={timeStarted}
                        navigateTo={this.navigateTo}
                        isSearching={this.state.isSearching}
                    />
                </div>
            </div>
        );
    }
}

SearchOverlay.propTypes = {
    handleClick: PropTypes.func,
    messages: PropTypes.shape({
        search: PropTypes.shape({
            text: PropTypes.func.isRequired
        }).isRequired
    }).isRequired,
    locale: PropTypes.shape({
        id: PropTypes.string
    }).isRequired,
    fetchSuggestions: PropTypes.func,
    reset: PropTypes.func,
    tracking: PropTypes.shape({
        trackEvent: PropTypes.func
    })
};

SearchOverlay.defaultProps = {
    handleClick: null,
    fetchSuggestions: null,
    reset: null
};

export default SearchOverlay;
